// Packages
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  summaryBar: {
    //display: "flex",
    background: theme.palette.primary.main,
    paddingLeft: "15px",
    paddingTop: "15px",
    fontSize: "22px",
    color: "white",
    alignItems: "center"
  },
  mainBanner: {
    paddingLeft: "15px",
    paddingTop: "1px",
    fontSize: "22px",
    border: "1px",
    width: "100%",
    borderColor: "black",
    color: "black",
    height: "80px",
    lineHeight: 1.8,
    backgroundColor: "white",
  },
  mainSubBanner: {
    fontSize: "14px",
    backgroundColor: "white",
  },
  mainSubBannerNA: {
    fontSize: "14px",
    color: "red",
    backgroundColor: "white",
  },
  lowerBanner: {
    paddingLeft: "15px",
    paddingRight: "10px",
    color: "white",
    height: "52px",
    backgroundColor: "white",

    borderBottomWidth: "1px",
    borderBottomColor: "black",
    borderBottomStyle: "solid"
  },
  formBannerHeader: {
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingTop: "1px",
    marginBottom: "20px",
    fontSize: "18px",
    color: "black",
    height: "40px",
    marginTop: "-10px",
    backgroundColor: "white",
  },
  formBanner: {
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingTop: "1px",
    marginBottom: "20px",
    fontSize: "14px",
    color: "black",
    height: "40px",
    marginTop: "-10px",
    backgroundColor: "white",
  },
  formBannerNA: {
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingTop: "1px",
    marginBottom: "20px",
    fontSize: "14px",
    color: "red",
    height: "40px",
    marginTop: "-10px",
    backgroundColor: "white",
  },
  requestAffiliationButton: {
    backgroundColor: "#1f4287",
    color: "white",
    width: "170px",
    float: "right",
    marginRight: "10px",
    marginTop:"10px",
  },
  summaryDesc: {
    background: theme.palette.primary.main,
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "15px",
    fontSize: "14px",
    color: "white",
    alignItems: "center"
  },
  productDescription: {
    background: theme.palette.primary.secondary,
    paddingTop: "15px",
    paddingLeft: "15px",
    paddingRight: "10px",
    paddingBottom: "-15px",
    marginBottom: "-25px",
    fontSize: "14px",
    color: "black",
  },
  buttonBar: {
    display: "flex",
    marginTop: "20px"
  },
  cardGrid: {
    display: "flex",
    marginTop: "20px",
  },
  bigCardGrid: {
    display: "flex",
    marginTop: "20px",
  },
  mainCardGrid: {
    marginTop: "20px",
    height: "500px",
    overflow: "scroll",
    width: "50%",
  },
}));
