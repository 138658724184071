// Packages
import React from "react";

// MUI Components
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";


import STATES from "enums/STATES";


// Styles
import { useStyles } from "./styles";

export default function Jurisdiction(props) {
    const classes = useStyles();
    const editMode = props.editMode;
    const allStatesData = Object.values(STATES);

    
      //we MAY want to use selectStateChip IF we come up with a UX to preselect the states that the user is requesting affiliation for.
      /*const selectStateChip = (value) => {
        const currentArr = props.statesToDisplayChip;
        if(editMode === true){
            let alreadyExists = false;
            for(const i in currentArr){
                if(!currentArr[i] || !currentArr[i].key){
                    continue;
                }
                if(currentArr[i].key.toLowerCase() === value.toLowerCase()){
                    alreadyExists = true;
                    currentArr.splice(i, 1);
                }
            }
            if(alreadyExists !== true){

                allStatesData.map( (item) => {
                    if(item.key.toLowerCase() === value.toLowerCase()){
                        currentArr.push(item);
                    }
                });
            }
            if(currentArr.length === 0){
                console.log('found no states!');
                currentArr.push("N/A");
            }
            
            props.setStatesToDisplayChip(currentArr);
            const badStates = ['ALLSTATES', 'MULTISTATE', 'MU'];
            const dispStatesData = allStatesData.filter((item) => {
                return !badStates.includes(item.key);
            });
            const states = dispStatesData.sort((a,b) => {
            if(a.key.toLowerCase() > b.key.toLowerCase()){
                return 1;
            }
            if(a.key.toLowerCase() < b.key.toLowerCase()){ 
                return -1;
            }
            return 0;
            });
            props.setStates(states);
        }
      };*/
  


      if (props.statesToDisplayChip.length > 0) {
        return props.states.map((state, key) => {
          const isStateValid = props.statesToDisplayChip.filter((item) => {
            return item.key === state.key;
          });
          const isStateProhibited = props.statesToValidate.filter((item) => {
            return item.key === state.key;
          });
          
        let stateChipClass = classes.chipNotFound;
        if(editMode){
            stateChipClass = isStateValid.length > 0 ? classes.chipSelectedEdit : classes.chipNotFoundEdit; 
        }
        else{
            stateChipClass = isStateValid.length > 0 ? classes.chipSelected : classes.chipNotFound; 
        }


        let ToolTipAppend = "";
        if(isStateValid.length > 0){
          stateChipClass = classes.chipSelected;
          ToolTipAppend = ": Affiliated";
        }
        else if(isStateProhibited.length > 0){
          stateChipClass = classes.chipNotFound;
          ToolTipAppend = ": Available";
        } 
        else{
          stateChipClass= classes.chipNotFoundSlash;
          ToolTipAppend = ": Not Available";
        }
        if(!props.FRLType && stateChipClass === classes.chipNotFoundSlash){
          stateChipClass = classes.chipNotFound;
        }
        
        
            return <Tooltip
              key={state.key}
              title={`${state.title}${ToolTipAppend}`}
              name={`jurisdiction-tooltip-${state.title}`}
              id={`jurisdiction-tooltip-${state.key}`}
              PopperProps={{
                "data-test": `line-item-${state.key}-jurisdiction-tooltip`,
              }}
              placement="top">
              <Chip
                name={`jurisdiction-chip-${state.title}`}
                id={`jurisdiction-chip-${state.key}`}
                key={state.key}
                variant="outlined"
                label={state.key}
                size="small"
                className={stateChipClass}
                data-test={`chip${state.key}`}
                clickable={false}
                //onClick={() => selectStateChip(state.key)} //may reuse for requesting affiliation
              />
            </Tooltip>;
          
          
          
        });
        
      }
  
      let statesString = '';
      if(props.states){
      for (let i = 0; i < props.states.length; i++) {
        statesString = statesString + props.states[i]['abbreviation'] + ',';
      }
      statesString = statesString.slice(0,-1);
    }

  
      return (
        <Chip
          name={`no-jurisdiction-chip-${statesString}`}
          id={`no-jurisdiction-chip-${statesString}`}
          key={`no-jurisdiction-chip-${statesString}`}
          variant="outlined"
          size="small"
          label={statesString}
          className={classes.chip}
          data-test={`chip${statesString}`}
        />
      );


}
