// Packages
import React, { useState, useContext } from "react";

// MUI Components
import { Container, Card, CardHeader, Grid, Hidden, Snackbar, CardContent  } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';


// Components
import Filters from "components/Filters";
import DownloadHistoryCard from "components/DownloadHistoryCard";

// Context
import MeContext from "contexts/MeContext";

// Styles
import { useStyles } from "./styles";

export default function BrowsePicker(props) {
  const classes = useStyles();
  const me = useContext(MeContext);

  return (
    <Container disableGutters className={classes.container}>
      <Card elevation={5}>
        {/* Clear Button */}
        <CardHeader
          className={classes.cardHeader}
          title="Filter"
          action={
            <IconButton
              aria-label="clear"
              color="primary"
              onClick={() => {
                props.clearAll();
                props.setSupportingDocsType([]);
                props.setSearchTerm("");
                props.setResetMaterialTypesTop(true);
                props.setPlans([]);
                props.setSelectablePlans([]);
                props.setPrograms([]);
                props.setSelectablePrograms([]);
                props.setPackageTypeCategory([]);
                props.setSubMaterialTypes({});
                props.setShowSubMaterialTypes(false);
                props.setDebounceSearchTerm("");
              }}
            >
              <ClearIcon />
            </IconButton>
          }
        />

        {/* Filters Container */}
        <Filters
          searchTerm={props.searchTerm}
          setSearchTerm={props.setSearchTerm}
          line={props.line}
          setLine={props.setLine}
          lineClass={props.lineClass}
          setLineClass={props.setLineClass}
          packageType={props.packageType}
          setPackageType={props.setPackageType}
          packageTypeCategory={props.packageTypeCategory}
          setPackageTypeCategory={props.setPackageTypeCategory}
          apiPackageTypes={props.apiPackageTypes}
          setAPIPackageTypes={props.setAPIPackageTypes}
          supportingDocsType={props.supportingDocsType}
          setSupportingDocsType={props.setSupportingDocsType}
          states={props.states}
          setStates={props.setStates}
          setTerm={props.setTerm}
          effectiveDate={props.effectiveDate}
          setEffectiveDate={props.setEffectiveDate}
          effectiveOldestDate={props.effectiveOldestDate}
          setEffectiveOldestDate={props.setEffectiveOldestDate}
          checkedCurrentDate={props.checkedCurrentDate}
          setCheckedCurrentDate={props.setCheckedCurrentDate}
          checkedCurrentOldestDate={props.checkedCurrentOldestDate}
          setCheckedCurrentOldestDate={props.setCheckedCurrentOldestDate}
          productLineHasClasses={props.productLineHasClasses}
          setProductLineHasClasses={props.setProductLineHasClasses}
          doUpperClear={true}
          resetMaterialTypesTop={props.resetMaterialTypesTop}
          setResetMaterialTypesTop={props.setResetMaterialTypesTop}
          updateUI={props.updateUI}
          plans={props.plans}
          setPlans={props.setPlans}
          selectablePlans={props.selectablePlans}
          setSelectablePlans={props.setSelectablePlans}
          programs={props.programs}
          setPrograms={props.setPrograms}
          selectablePrograms={props.selectablePrograms}
          setSelectablePrograms={props.setSelectablePrograms}
          renderStates={props.renderStates}
          setRenderStates={props.setRenderStates}
          tempStates={props.tempStates}
          setTempStates={props.setTempStates}
          allStatesSelected={props.allStatesSelected}
          setAllStatesSelected={props.setAllStatesSelected}
          excludeFileContent={props.excludeFileContent}
          setExcludeFileContent={props.setExcludeFileContent}
          showSubMaterialTypes={props.showSubMaterialTypes}
          setShowSubMaterialTypes={props.setShowSubMaterialTypes}
          subMaterialTypes={props.subMaterialTypes}
          setSubMaterialTypes={props.setSubMaterialTypes}
          debounceSearchTerm={props.debounceSearchTerm}
          setDebounceSearchTerm={props.setDebounceSearchTerm}
        />
        {/* TODO: refactor the downloads section OUT of BrowsePicker and INTO the browseScreen */}
        <CardContent className={classes.downloadCard} onClick={() => props.setShowDownloads(!props.showDownloads)}>
          <span className={classes.leftDownloadContent}>Recent Downloads</span>
          {!props.showDownloads &&
            <ArrowDropDownIcon className={classes.menuItemA}></ArrowDropDownIcon>
          }
          {props.showDownloads &&
            <ArrowUpIcon className={classes.menuItemB}></ArrowUpIcon>
          }
         
          
        </CardContent>
          {props.showDownloads && me?.userId && (
            <DownloadHistoryCard
              lineKey={props.line}
              stateKey={props.renderStates}
              userId={me.userId}
              browseScreen={true}
            ></DownloadHistoryCard>
          )}
      </Card>
    </Container>
  );
}
