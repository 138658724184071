import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// MUI Components
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
//import Tabs from '@mui/material/Tabs'; //could be useful later
//import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Components
import SampleCard from 'components/SampleCard';
import ProductDialog from "components/ProductDialog";
import LandingPageDialog from "components/LandingPageDialog";


// Context
import MeContext from "contexts/MeContext";
import LandingPagesContext from "contexts/LandingPagesContext";
import ProductsContext from "contexts/ProductsContext";

// Services
import * as ProductApi from "apis/ProductApi";

// Styles
import { useStyles } from "./styles";

export default function ProductScreen(props) {
  
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  //Shapes: M = main card, C = card, I = Image, X = empty
  // A C (card) with title === 'other' will set a LONG card, to match the M (main card) height.
  const [productShape, setProductShape] = useState(['M', 'C']);//'I', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C'
  const [productImages, setProductImages] = useState([]);
  const [states, setStates] = useState([]);
  const [mainFolder, setMainFolder] = useState(0);
  const [allProductIds, setAllProductIds] = useState([]);
  const [allProductNames, setAllProductNames] = useState([]);
  const [currentLandingPage, setCurrentLandingPage] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const [doReset, setDoReset] = useState();
  const [currentProductName, setCurrentProductName] = useState('');
  const [currentProduct, setCurrentProduct] = useState();
  const [currentLandingPageName, setCurrentLandingPageName] = useState("");
  const [currentLandingPageDesc, setCurrentLandingPageDesc] = useState("");
  const [productDescription, setProductDescription] = useState("");

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [createLandingPageDialogOpen, setCreateLandingPageDialogOpen] = useState(false);
  const [landingPageId, setLandingPageId] = useState();
  const [productId, setProductId] = useState();
  const [productKeyName, setProductKeyName] = useState();
  const [currentLandingPageWidgets, setCurrentLandingPageWidgets] = useState();
  const [updateSearches, setUpdateSearches] = useState(false);
  const [singleResult, setSingleResult] = useState(false);
  const [allLandingPages, setAllLandingPages] = useState();
  const [productsReady, setProductsReady] = useState(false);
  const [landingPagesReady, setLandingPagesReady] = useState(false);
  const [landingPageProducts, setLandingPageProducts] = useState();
  const [displayLandingPageProducts, setDisplayLandingPageProducts] = useState();
  const [doInternalReset, setDoInternalReset] = useState(false);
  const [isAffiliatedAll, setIsAffiliatedAll] = useState(true);
  const [isAffiliatedForms, setIsAffiliatedForms] = useState(true);
  const [isAffiliatedRates, setIsAffiliatedRates] = useState(true);
  const [isAffiliatedLoss, setIsAffiliatedLoss] = useState(true);

  const [formsAffiliated, setFormsAffiliated] = useState(true);
  const [formsAvailable, setFormsAvailable] = useState(true);
  const [lossAffiliated, setLossAffiliated] = useState(true);
  const [lossAvailable, setLossAvailable] = useState(false);
  const [rulesAffiliated, setRulesAffiliated] = useState(true);
  const [rulesAvailable, setRulesAvailable] = useState(false);

  

  const landingPages = useContext(LandingPagesContext);
  const products = useContext(ProductsContext);

  //const thisImgStr = process.env.DIRECT_PUBLIC_ASSETS_BUCKET //`https://${ENV}-direct-public-assets.s3.amazonaws.com/product-pictures`;


  const navigate = useNavigate();
  const [snack, setSnack] = useState({
    open: false,
    note: null,
    severity: "info",
  });


  
  useEffect(() => {
    const thisProductId = props.productData ? props.productData : 'auto';

    if(productsReady === true && landingPagesReady === true && allLandingPages){
      let thisLandingPage = null;

      for(const i in allLandingPages){
        if(allLandingPages && allLandingPages[i] && allLandingPages[i].keyName === thisProductId){
          thisLandingPage = allLandingPages[i];
          //window.location.href = window.location.origin + `/#/product?q=${props.productData}`;
        }
      }
      if(thisLandingPage === null){
        navigate("/");
        return null;
      }
      
      setCurrentLandingPageName(thisLandingPage.name);
      setCurrentLandingPageDesc(thisLandingPage.description);
      const theseLandingPageProducts = thisLandingPage.products;
      setAllProductIds(theseLandingPageProducts);
      const landingPageWidgets = thisLandingPage.widgets;
      setCurrentLandingPageWidgets(landingPageWidgets);
      setLandingPageId(thisLandingPage.landingPageId);
      setCurrentLandingPage(thisLandingPage);
      setUpWidgets();

      const newProducts = [];

      allProducts.filter((product) => {
        if(thisLandingPage.products.includes(product.productId)){
          newProducts.push(product);
        }
      });
      if(!productKeyName){
        setProductKeyName(newProducts[0].keyName);
      }
      setLandingPageProducts(newProducts);
    }

  }, [allLandingPages, props.productData, productsReady, landingPagesReady]);

  useEffect(() => {
    if(landingPageProducts && landingPageProducts.length > 0){
      setCurrentProduct(landingPageProducts[0]);
      setProductKeyName(landingPageProducts[0].keyName);
      setProductId(landingPageProducts[0].productId);
      setUpWidgets();
      setDisplayLandingPageProducts(landingPageProducts.sort((a, b) =>
        a.name.localeCompare(b.name)));
    }
  }, [landingPageProducts]);
  

  //useEffect(() => {
    /*window.gtag("event", "page_view", {
      page_title: "Product Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
      send_to: `${process.env.REACT_APP_GA}`
    });*/

  useEffect(() => {
    setAllProducts(products);
  }, [products]);
  useEffect(() => {
    setAllLandingPages(landingPages);

      let thisLandingPage = null;//firstLandingPage;
      for(const i in landingPages){
        if(landingPages[i].keyName === props.productData){
          thisLandingPage = landingPages[i];
          //window.location.href = window.location.origin + `/#/product?q=${props.productData}`;
        }
      }
      setLandingPagesReady(true);    

  }, [landingPages]);

  useEffect(() => {
    if(allProducts && allProducts.length > 0){
      let thisProduct = allProducts[0];
      const theseProductNames = [];
      let foundProduct = false;
      //words.filter((word) => word.length > 6);
      if(currentLandingPage){
        
        allProducts.filter((product) => {
          if(currentLandingPage.products.includes(product.productId)){
            if(!foundProduct && productKeyName === product.keyName){
              thisProduct = product;
              foundProduct = true;
            }
            theseProductNames.push(product.name);
          }
        });

        if(!foundProduct){
          allProducts.filter((product) => {
            if(currentLandingPage.products.includes(product.productId)){
              if(!foundProduct){
                thisProduct = product;
                foundProduct = true;
              }
            }
          });
        }
      

        setAllProductNames(theseProductNames);
        setProductKeyName(thisProduct.keyName);//???currentLandingPage
      }
      setProductId(thisProduct.productId);

      setCurrentProduct(thisProduct);

      setProductsReady(true);
    }
  }, [allProducts]);

  useEffect(() => {
    if(productsReady === true && landingPagesReady === true){
      setUpWidgets();
    }
  }, [productsReady, landingPagesReady]);

  useEffect(() => {
    setUpWidgets();
  }, [currentLandingPageWidgets, currentProduct, doReset, productKeyName]);

  useEffect(() => {
    if(currentProduct){
      setIsAffiliatedAll(currentProduct.isAffiliatedAll ? currentProduct.isAffiliatedAll : false);
      setIsAffiliatedForms(currentProduct.isAffiliatedForms ? currentProduct.isAffiliatedForms : false);
      setIsAffiliatedRates(currentProduct.isAffiliatedRates ? currentProduct.isAffiliatedRates : false);
      setIsAffiliatedLoss(currentProduct.isAffiliatedLoss ? currentProduct.isAffiliatedLoss : false);

      setFormsAffiliated(currentProduct.formsAffiliated ? currentProduct.formsAffiliated : false);
      setFormsAvailable(currentProduct.formsAvailable ? currentProduct.formsAvailable : false);
      setRulesAffiliated(currentProduct.rulesAffiliated ? currentProduct.rulesAffiliated : false);
      setRulesAvailable(currentProduct.rulesAvailable ? currentProduct.rulesAvailable : false);
      setLossAffiliated(currentProduct.lossAffiliated ? currentProduct.lossAffiliated : false);
      setLossAvailable(currentProduct.lossAvailable ? currentProduct.lossAvailable : false);

    }
  }, [currentProduct, products]);


  function doUpdate(key, value){
    const newCards = cards.filter((x) => {
      if(x.title === key){
        x.verbiage = value;
        setDoReset(!doReset);
      }
      return x;
    });
    setCards(newCards);


    const newProducts = [];
    displayLandingPageProducts.filter((lpProduct) => {
      if(lpProduct.keyName !== productKeyName){
        newProducts.push(lpProduct);
      }
      else{
        lpProduct[key] = value;
        newProducts.push(lpProduct);
      }
    });
    setDisplayLandingPageProducts(newProducts);

  }

  function updateProductsArray(newProduct){
    if(!newProduct){
      return;
    }
    const newProducts = [];
    allProducts.filter((x) => {
      if(x.productId === newProduct.productId){
        newProducts.push(newProduct);
      }
      else{
        return newProducts.push(x);
      }
    });
    setAllProducts(newProducts);
  }

  function setUpWidgets(initProduct){
    if(!currentProduct && !initProduct){
      return;
    }
    try{
      setLoading(true);
      const thisProduct = initProduct ? initProduct : currentProduct;
      const allWidgets = thisProduct.widgets;
      //TODO: change default shapes?
      const tempProductShape = thisProduct.shape ? thisProduct.shape : [ 'M', 'C',];//'I', 'C', 'C', 'C', 'C', 'C', 'C', 'C', 'C'
      setProductShape(tempProductShape);
      const tempProductImages = thisProduct.images ? thisProduct.images : null;
      setProductImages(tempProductImages);
      const newCards = [];
      setProductKeyName(thisProduct.keyName);
      setIsAffiliatedAll(thisProduct.isAffiliatedAll ? thisProduct.isAffiliatedAll : true);
      setIsAffiliatedForms(thisProduct.isAffiliatedForms ? thisProduct.isAffiliatedForms : true);
      setIsAffiliatedRates(thisProduct.isAffiliatedRates ? thisProduct.isAffiliatedRates : true);
      setIsAffiliatedLoss(thisProduct.isAffiliatedLoss ? thisProduct.isAffiliatedLoss : false);

      setRulesAffiliated(thisProduct.rulesAffiliated ? thisProduct.rulesAffiliated : false);
      setRulesAvailable(thisProduct.rulesAvailable ? thisProduct.rulesAvailable : false);
      setFormsAffiliated(thisProduct.formsAffiliated ? thisProduct.formsAffiliated : false);
      setFormsAvailable(thisProduct.formsAvailable ? thisProduct.formsAvailable : false);
      setLossAffiliated(thisProduct.lossAffiliated ? thisProduct.lossAffiliated : false);
      setLossAvailable(thisProduct.lossAvailable ? thisProduct.lossAvailable : false);

      allWidgets.map((widget, i) => {
        if(widget.type === 'text'){
          const thisWidget = thisProduct[widget.name];
          //const thisName = widget.name.charAt(0).toUpperCase() + widget.name.slice(1);
          const thisName = widget.name ? widget.name: i;
          if(thisName === 'Overview'){
            setProductDescription(thisWidget);
          }

          newCards.push({id: thisName, title: thisName, verbiage: thisWidget, type: 'text'},);
        }

        if(widget.type === 'states'){
          const thisWidget = thisProduct[widget.name];
          //const thisName = widget.name.charAt(0).toUpperCase() + widget.name.slice(1);
          const thisName = widget.name ? widget.name: i;
          setStates(thisWidget);
          newCards.push({id: thisName, title: thisName, verbiage: thisWidget, type: 'jurisdiction'},);

        }

        if(widget.type === 'list'){
          const thisWidget = thisProduct[widget.name];
          //const thisName = widget.name.charAt(0).toUpperCase() + widget.name.slice(1);
          const thisName = widget.name ? widget.name: i;

          newCards.push({id: thisName, title: thisName, verbiage: thisWidget, type: 'list'},);
        }

        if(widget.type === 'search'){
          const thisWidget = thisProduct[widget.name];
          //const thisName = widget.name.charAt(0).toUpperCase() + widget.name.slice(1);
          const thisName = widget.name ? widget.name: i;

          newCards.push({id: thisName, title: thisName, verbiage: thisWidget, type: 'search'},);
        }

        if(widget.type === 'image'){
          const thisWidget = thisProduct[widget.name];
          const thisName = widget.name ? widget.name: i;
          newCards.push({id: thisName, title: thisName, verbiage: JSON.parse(thisWidget), type: 'image'},);
        }
      });
      setCards(newCards);
      setCurrentProductName(thisProduct.name);
      setLoading(false);
    }catch(error) {
      console.log('setupWidgets error: ', error);
    } finally {
      setLoading(false);
    }
  }

  function switchProduct(product){
    try{
      if(product.keyName === currentProduct.keyName){
        return;
      }
      console.log(product);//TODO: take this out later, when we're sure everything works well
      setLoading(true);
      setCurrentProduct(product);
      setProductKeyName(product.keyName);
      setProductId(product.productId);
      setUpWidgets(product);
      setDoReset(!doReset);
      setLoading(false);

      setIsAffiliatedAll(product.isAffiliatedAll ? product.isAffiliatedAll : false);
      setIsAffiliatedForms(product.isAffiliatedForms ? product.isAffiliatedForms : false);
      setIsAffiliatedRates(product.isAffiliatedRates ? product.isAffiliatedRates : false);
      setIsAffiliatedLoss(product.isAffiliatedLoss ? product.isAffiliatedLoss : false);

      setRulesAffiliated(product.rulesAffiliated ? product.rulesAffiliated : false);
      setRulesAvailable(product.rulesAvailable ? product.rulesAvailable : false);
      setFormsAffiliated(product.formsAffiliated ? product.formsAffiliated : false);
      setFormsAvailable(product.formsAvailable ? product.formsAvailable : false);
      setLossAffiliated(product.lossAffiliated ? product.lossAffiliated : false);
      setLossAvailable(product.lossAvailable ? product.lossAvailable : false);

    }catch(error) {
      console.log('switchProduct error: ', error);
    } finally {
      setLoading(false);
    }
    setStates(product.states);//currentProduct
  }

  async function handleCreate(name) {
    try{
      setLoading(true);
      setCreateDialogOpen(false);
      const resp = await ProductApi.create(name);
      
      setLoading(false);
    }catch(error) {
      console.log('handleCreate error: ', error);
    } finally {
      setLoading(false);
    }
  }

  function getProductsBar() {
    //this is not used right now, but I used it to create the initial products and landing pages, and we MAY want to ue it if we want to create more.
    return (
      <Container maxWidth={false} className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <TextField
                variant="outlined"
                fullWidth
                autoCorrect="off"
                spellCheck="false"
                label="Search"
                placeholder="Search"
                data-test="orgsFilterSearch"
                className={classes.searchText}
              />
            </Grid>
            <Grid item></Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateDialogOpen(true)}
              >
                Create Product
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setCreateLandingPageDialogOpen(true)}
              >
                Create LandingPage
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  /* Not currently used, was used for tabs
  function a11yProps(index) {
    return {
      id: `main-card-tab-${index}`,
      'aria-controls': `main-card-tabpanel-${index}`,
      name: `main-card-tab-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    console.log(newValue);
    setMainFolder(newValue);
  };*/
    

    const me = useContext(MeContext);
    let cardI = 0;
    let imgI = 0;
    return (
        <>
        <div className={classes.summaryBar} name={`landing-page-bar-${props.title}`} id={`landing-page-bar-${props.title}`}>
            {currentLandingPageName}
            <br></br>
            <div className={classes.summaryDesc} name={`landing-page-desc-${props.title}`} id={`landing-page-desc-${props.title}`}>{currentLandingPageDesc}</div>
        </div>
        {/*me && userUtil.isEmployee(me) && userUtil.isAdmin(me) && getProductsBar()//only for admins*/}
        <div className={classes.buttonBar} key={`button-bar-${props.title}`} name={`button-bar-${props.title}`} id={`button-bar-${props.title}`}>
          <Grid container spacing={2} alignItems="center" key={`product-grid-${props.title}`} name={`product-grid-${props.title}`} id={`product-grid-${props.title}`}>
            {displayLandingPageProducts && displayLandingPageProducts.length > 0 && displayLandingPageProducts.map((product, i) => {
                const checkedStyle = {color: "white", background: "#14328d", borderWidth: "1px", borderColor: "#000" };
                const unCheckedStyle =  {color: "black", background: "white", borderWidth: "1px", borderColor: "#000"  };
                return (<Grid item key={i} name={`product-grid-i-${i}`} id={`product-grid-i-${i}`}>
                  <Button
                    variant="outlined"
                    name={`product-button-${product.name}`}
                    key={`product-button-${product.name}`} 
                    id={`product-button-${product.name}`}
                    style={ productKeyName === product.keyName ? checkedStyle : unCheckedStyle}
                    onClick={() => switchProduct(product)}
                  >
                    {product.name}
                  </Button>
                </Grid>);
              })
            }
            
          </Grid>
        </div>
        <div className={classes.productDescription} name={`cards-top-grid-${props.title}`} id={`product-button-${props.title}`}><span >{productDescription}</span></div>
        <div>
            <Grid container spacing={1} alignItems='center' key={`cards-top-grid-${props.title}`} name={`cards-top-grid-${props.title}`} id={`product-button-${props.title}`} >
                {!loading && singleResult === false && productShape.map((aShape, i) => {

                    const card = cards[cardI] ? cards[cardI] : null;
                    if(aShape === 'X' || card === null){
                      return (<Grid item xs={12} sm={6} md={4} lg={4} xl={4} className={classes.cardGrid} key={i} name={`card-grid-${i}`} id={`card-grid-${i}`}>
                        
                        </Grid>);
                    }

                    if(aShape === 'I' || card === null){
                      const thisImage = productImages[imgI];
                      let imageSizes =  {xs:4, sm:4, md:4, lg:4, xl:4};
                      if(imgI === 1){
                        imageSizes =  {xs:8, sm:8, md:8, lg:8, xl:8};
                      }
                      imgI = imgI + 1;
                      return (<Grid item xs={imageSizes.xs} sm={imageSizes.sm} md={imageSizes.md} lg={imageSizes.lg} xl={imageSizes.xl} className={classes.cardGrid} key={i} name={`card-grid-${i}`} id={`card-grid-${i}`}>
                        {/* <ImageList sx={{ height: 800 }} cols={1} > */}

                        <ImageList cols={1} id={`product-image-list-${imgI}`} name={`product-image-list-${imgI}`}>
                            <ImageListItem key={thisImage} id={`product-image-list-item-${imgI}`} name={`product-image-list-item-${imgI}`}>
                              <img
                                id={`product-image-${imgI}`}
                                name={`product-image-${imgI}`}
                                srcSet={`${thisImage}`}
                                src={`${thisImage}`}
                                alt={`image-${imgI}`}
                                loading="lazy"
                              />
                            </ImageListItem>
                        </ImageList>

                        </Grid>);
                    }


                    if(aShape === 'M' || card === null){
                      let mainCardSizes =  {xs:12, sm:6, md:4, lg:4, xl:4};
                      //if(imgI === 1){
                      mainCardSizes =  {xs:8, sm:8, md:8, lg:8, xl:8};
                      //}

                      const cardAvailability = cards.find((card) => card.type &&  card.type === 'jurisdiction');
                      const cardOverview = cards.find((card) => card.title && card.title.toLowerCase() === 'overview');
                      const cardForms = cards.find((card) => card.title && card.title.toLowerCase() === 'forms');
                      const cardRates = cards.find((card) => card.title && card.title.toLowerCase() === 'additional_materials');

                      const styleUnchecked = { color: "white", background: "grey", borderColor: "black", borderStyle: "solid", borderWidth: "1px" };
                      const styleChecked =  { color: "black", background: "white", borderColor: "black", borderStyle: "solid", borderWidth: "1px" };
                      
                      return (<Grid item xs={mainCardSizes.xs} sm={mainCardSizes.sm} md={mainCardSizes.md} lg={mainCardSizes.lg} xl={mainCardSizes.xl} className={classes.mainCardGrid} key={i} name={`main-card-grid-${i}`} id={`main-card-grid-${i}`} >
                        {/* TODO: reuse if we want tabs again
                        <Box style={{ borderBottom: 1, borderColor: 'divider', background: "black" }}>
                          <Tabs value={mainFolder} onChange={handleChangeTab} aria-label="main-card-grid-tabs" variant="fullWidth" centered style={{background: "black"}}>
                            <Tab style={mainFolder === 0 ?  styleChecked : styleUnchecked} label="Form Availability" {...a11yProps(0)} />
                            <Tab style={mainFolder === 1 ? styleChecked : styleUnchecked } label="Rates/Rules Availability" {...a11yProps(1)} />
                            <Tab style={mainFolder === 2 ? styleChecked : styleUnchecked} label="My Affiliations" {...a11yProps(2)} />
                          </Tabs>
                        </Box>*/}
                        <Grid item xs={8} sm={8} md={8} lg={12} xl={12} className={classes.bigCardGrid} key={card.id} name={`big-card-grid-${i}`} id={`big-card-grid-${i}`}>
                            {mainFolder < 2 && (<div id={`product-main-top-div-${i}`} name={`product-main-top-div-${i}`}>
                              <Box style={{ marginBottom: "-25px", backgroundColor: "white"}} id={`product-main-top-box-${i}`} name={`product-main-top-box-${i}`}>
                                <div className={classes.mainBanner} id={`product-main-banner`} name={`product-main-banner`}><b>Availability and Affiliation</b>
                                <Button
                                  id={`product-request-affiliation-button`}
                                  name={`product-request-affiliation-button`}
                                  className={classes.requestAffiliationButton}
                                  color="primary"
                                  variant="outlined"
                                  data-test="productScreen-item-product-request-button"
                                  onClick={props.handleRequestModal}
                                >
                                  REQUEST AFFILIATION
                                </Button> <div></div>
                                {isAffiliatedAll && (!isAffiliatedForms && !isAffiliatedRates && !isAffiliatedLoss) &&
                                  <div className={classes.mainSubBanner} id={`product-is-affiliated`} name={`product-is-affiliated`}>You are affiliated with this line.</div>
                                }
                                {!isAffiliatedAll &&
                                  <div className={classes.mainSubBannerNA} id={`product-not-affiliated`} name={`product-not-affiliated`}>You are not affiliated with this line.</div>
                                  
                                }
                                </div>
                              </Box>
                              <Grid item xs={8} sm={8} md={8} lg={12} xl={12} className={classes.cardGrid} key={card.id} name={`lower-card-grid-${i}`} id={`lower-card-grid-${i}`}>
                                <div>
                                <Box style={{ paddingBottom: "55px", backgroundColor: "white"}} id={`forms-box`} name={`forms-box`}>
                                <b className={classes.formBannerHeader} id={`forms-banner-header`} name={`forms-banner-header`}>Forms</b><br></br><br></br>
                                  {isAffiliatedForms &&
                                    <div className={classes.formBanner} id={`forms-affiliated`} name={`forms-affiliated`}>You are affiliated with forms.</div>
                                  }
                                  {isAffiliatedAll && !isAffiliatedForms &&
                                    <div className={classes.formBannerNA} id={`forms-not-affiliated`} name={`forms-not-affiliated`}>You are not affiliated with forms.</div>
                                  }
                                </Box>
                                  <SampleCard
                                    key={cardAvailability.id}
                                    title={cardAvailability.title}
                                    verbiage={ mainFolder === 0 ? formsAvailable :  formsAvailable}
                                    type={cardAvailability.type}
                                    doUpdate={doUpdate}
                                    states={mainFolder === 0 ? formsAvailable : formsAvailable}
                                    statesAvailable={formsAvailable}
                                    statesAffiliated={formsAffiliated}
                                    landingPageId={landingPageId}
                                    productId={productId}
                                    doReset={doReset}
                                    setDoReset={setDoReset}
                                    productName={currentProductName}
                                    loading={loading}
                                    productKeyName={productKeyName}
                                    setCurrentProduct={setCurrentProduct}
                                    updateProductsArray={updateProductsArray}
                                    updateSearches={updateSearches}
                                    setUpdateSearches={setUpdateSearches}
                                    i={i}
                                    singleResult={singleResult}
                                    setSingleResult={setSingleResult}
                                    doInternalReset={doInternalReset}
                                    setDoInternalReset={setDoInternalReset}
                                    mainCard={true}
                                    FRLType={"forms"}
                                />
                                { !isAffiliatedAll &&
                                    <div className={classes.lowerBanner} id={`forms-low-spacer`} name={`forms-low-spacer`}></div>
                                }
                              </div><div>
                              <Box style={{ paddingBottom: "55px", backgroundColor: "white"}} id={`rules-box`} name={`rules-box`}>
                                
                              <b className={classes.formBannerHeader} id={`rules-banner-header`} name={`rules-banner-header`}>Rules</b><br></br><br></br>
                                  {isAffiliatedRates &&
                                    <div className={classes.formBanner} id={`rules-affiliated`} name={`rules-affiliated`}>You are affiliated with rules.</div>
                                  }
                                  {isAffiliatedAll && !isAffiliatedRates &&
                                    <div className={classes.formBannerNA} id={`rules-not-affiliated`} name={`rules-not-affiliated`}>You are not affiliated with rules.</div>
                                  }
                              </Box>
                                <SampleCard
                                    key={cardOverview.id}
                                    title={cardOverview.title}
                                    verbiage={ mainFolder === 0 ? rulesAvailable :  rulesAvailable}
                                    type={cardAvailability.type}
                                    doUpdate={doUpdate}
                                    states={mainFolder === 0 ? rulesAvailable : rulesAvailable}
                                    statesAvailable={rulesAvailable}
                                    statesAffiliated={rulesAffiliated}
                                    landingPageId={landingPageId}
                                    productId={productId}
                                    doReset={doReset}
                                    setDoReset={setDoReset}
                                    productName={currentProductName}
                                    loading={loading}
                                    productKeyName={productKeyName}
                                    setCurrentProduct={setCurrentProduct}
                                    updateProductsArray={updateProductsArray}
                                    updateSearches={updateSearches}
                                    setUpdateSearches={setUpdateSearches}
                                    i={i}
                                    singleResult={singleResult}
                                    setSingleResult={setSingleResult}
                                    doInternalReset={doInternalReset}
                                    setDoInternalReset={setDoInternalReset}
                                    mainCard={true}
                                    FRLType={"rules"}
                                />
                                { !isAffiliatedAll &&
                                    <div className={classes.lowerBanner} id={`rules-low-spacer`} name={`rules-low-spacer`}></div>
                                }
                              </div><div>
                              
                              <Box style={{ paddingBottom: "55px", backgroundColor: "white"}} id={`loss-banner-header`} name={`loss-banner-header`}>
                                <b className={classes.formBannerHeader} id={`loss-banner-header`} name={`loss-banner-header`}>Loss Costs</b><br></br><br></br>
                                  {isAffiliatedLoss &&
                                    <div className={classes.formBanner} id={`loss-affiliated`} name={`loss-affiliated`}>You are affiliated with loss costs.</div>
                                  }
                                  {isAffiliatedAll && !isAffiliatedLoss &&
                                    <div className={classes.formBannerNA} id={`loss-not-affiliated`} name={`loss-not-affiliated`}>You are not affiliated with loss cost.</div>
                                  }
                                </Box>
                                  <SampleCard
                                    key={cardAvailability.id}
                                    title={cardAvailability.title}
                                    verbiage={ mainFolder === 0 ? lossAvailable :  lossAvailable}
                                    type={cardAvailability.type}
                                    doUpdate={doUpdate}
                                    states={mainFolder === 0 ? lossAvailable : lossAvailable}
                                    statesAvailable={lossAvailable}
                                    statesAffiliated={lossAffiliated}
                                    landingPageId={landingPageId}
                                    productId={productId}
                                    doReset={doReset}
                                    setDoReset={setDoReset}
                                    productName={currentProductName}
                                    loading={loading}
                                    productKeyName={productKeyName}
                                    setCurrentProduct={setCurrentProduct}
                                    updateProductsArray={updateProductsArray}
                                    updateSearches={updateSearches}
                                    setUpdateSearches={setUpdateSearches}
                                    i={i}
                                    singleResult={singleResult}
                                    setSingleResult={setSingleResult}
                                    doInternalReset={doInternalReset}
                                    setDoInternalReset={setDoInternalReset}
                                    mainCard={true}
                                    FRLType={"loss"}
                                />
                                 { !isAffiliatedAll &&
                                    <div className={classes.lowerBanner} id={`loss-low-spacer`} name={`loss-low-spacer`}></div>
                                  }
                              </div><div></div>
                            </Grid>
                            </div>)}
                            {mainFolder === 2 && <SampleCard
                                key={cardOverview.id}
                                title={cardOverview.title}
                                verbiage={cardOverview.verbiage}
                                type={cardOverview.type}
                                //TODO: actually change the data presented based on selection
                                statesAvailable={formsAffiliated}
                                statesAffiliated={formsAvailable}
                                doUpdate={doUpdate}
                                states={states}
                                landingPageId={landingPageId}
                                productId={productId}
                                doReset={doReset}
                                setDoReset={setDoReset}
                                productName={currentProductName}
                                loading={loading}
                                productKeyName={productKeyName}
                                setCurrentProduct={setCurrentProduct}
                                updateProductsArray={updateProductsArray}
                                updateSearches={updateSearches}
                                setUpdateSearches={setUpdateSearches}
                                i={i}
                                singleResult={singleResult}
                                setSingleResult={setSingleResult}
                                doInternalReset={doInternalReset}
                                setDoInternalReset={setDoInternalReset}
                                mainCard={true}
                                needSpace={true}
                            />}
                            
                          </Grid>
                          
                        </Grid>);
                    }

                    cardI = cardI + 1;
                    
                    
                    return (<Grid item xs={12} sm={6} md={4} lg={4} xl={4} className={classes.cardGrid} key={card.id} name={`card-grid-${i}`} id={`card-grid-${i}`}>
                            <SampleCard
                                key={card.id}
                                title={card.title}
                                verbiage={card.verbiage}
                                type={card.type}
                                doUpdate={doUpdate}
                                states={states}
                                landingPageId={landingPageId}
                                productId={productId}
                                doReset={doReset}
                                setDoReset={setDoReset}
                                productName={currentProductName}
                                loading={loading}
                                productKeyName={productKeyName}
                                setCurrentProduct={setCurrentProduct}
                                updateProductsArray={updateProductsArray}
                                updateSearches={updateSearches}
                                setUpdateSearches={setUpdateSearches}
                                i={i}
                                singleResult={singleResult}
                                setSingleResult={setSingleResult}
                                doInternalReset={doInternalReset}
                                setDoInternalReset={setDoInternalReset}
                            />
                    </Grid>);
                })}
                {!loading && singleResult !== false && (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.cardGrid} key={cards[singleResult].id} name={`card-single-${cards[singleResult].title}`} id={`card-single-${cards[singleResult].title}`} >
                        <SampleCard
                            key={cards[singleResult].id}
                            title={cards[singleResult].title}
                            verbiage={cards[singleResult].verbiage}
                            type={cards[singleResult].type}
                            doUpdate={doUpdate}
                            states={states}
                            landingPageId={landingPageId}
                            productId={productId}
                            doReset={doReset}
                            setDoReset={setDoReset}
                            productName={currentProductName}
                            loading={loading}
                            productKeyName={productKeyName}
                            setCurrentProduct={setCurrentProduct}
                            updateProductsArray={updateProductsArray}
                            updateSearches={updateSearches}
                            setUpdateSearches={setUpdateSearches}
                            i={-1}
                            singleResult={singleResult}
                            setSingleResult={setSingleResult}
                            doInternalReset={doInternalReset}
                            setDoInternalReset={setDoInternalReset}
                        />
                </Grid>
                )}
            </Grid>
            {createDialogOpen && (
              <ProductDialog
                handleCreate={() => handleCreate}
                handleClose={() => setCreateDialogOpen(false)}
                name={`product-dialog`}
                id={`product-dialog`}
                key={`product-dialog`}
              ></ProductDialog>
            )}
             {createLandingPageDialogOpen && (
              <LandingPageDialog
                handleCreate={() => handleCreate}
                handleClose={() => setCreateDialogOpen(false)}
                name={`landing-page-dialog`}
                key={`landing-page-dialog`}
                id={`landing-page-dialog`}
              ></LandingPageDialog>
            )}
        </div>
        {loading && <LinearProgress></LinearProgress>} 
        </>
    );
}